import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";

const basePath = environment.basePath;

@Injectable({ providedIn: "root" })
export class ForgetPasswordService {
  constructor(private http: HttpClient) {}
  forgetPassword(data) {
    return this.http.post<any[]>(`${basePath}/`, data);
  }
  resetPassword(id,data) {
    return this.http.patch<any[]>(`${basePath}/admins/change-password`, data);
  }
}
