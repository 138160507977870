import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { AuthenticationModule } from './main/authentication/authentication.module';
import { AuthGuard, ErrorInterceptor, JwtInterceptor } from 'app/auth/helpers';
import { MiscellaneousModule } from './main/miscellaneous/miscellaneous.module';

const appRoutes: Routes = [
  {
    path: 'question',
    loadChildren: () => import('./main/questions/questions.module').then(m => m.QuestionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'category',
    loadChildren: () => import('./main/category/category.module').then(m => m.CategoryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rule',
    loadChildren: () => import('./main/rule/rule.module').then(m => m.RuleModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./main/admins/admins.module').then(m => m.AdminsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'anecdote',
    loadChildren: () => import('./main/anecdotes/anecdotes.module').then(m => m.AnecdotesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'level-test',
    loadChildren: () => import('./main/level-test/level-test.module').then(m => m.LevelTestModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'definitions',
    loadChildren: () => import('./main/definitions/definitions.module').then(m => m.DefinitionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./main/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'coach',
    loadChildren: () => import('./main/coach/coach.module').then(m => m.CoachModule),
    // canActivate: [AuthGuard]
  },
  {
    path: '',
    canActivate: [AuthGuard], // Use the AuthGuard here
    loadChildren: () => import('./main/sample/sample.module').then(m => m.SampleModule),

  },
  {
    path: '**',
    redirectTo: '/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,
    AuthenticationModule,
    MiscellaneousModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
