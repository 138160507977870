export const locale = {
  lang: 'pt',
  data: {
    MENU: {
      HOME: 'Casa',
      SAMPLE: 'Amostra',
      QUESTION: {
        COLLAPSIBLE: 'Questions',
        QUESTIONS:'Questions',
        BADGE: '2',
        VIEW: 'View',
        ADD: 'add'
      },
      CATEGORY: {
        COLLAPSIBLE: 'Category',
        CATEGORY:'Category',
        BADGE: '2',
        VIEW: 'View',
        ADD: 'Add'
      },
      RULE: {
        COLLAPSIBLE: 'RULE',
        RULE:'RULE',
        BADGE: '2',
        VIEW: 'View',
        ADD: 'Add'
      },
    },
  }
}
