export const locale = {
  lang: 'fr',
  data: {
    MENU: {
      HOME: 'Accueil',
      SAMPLE: 'Échantillon',
      QUESTION: {
        COLLAPSIBLE: 'Questions',
        QUESTIONS:'Questions',
        BADGE: '2',
        VIEW: 'Consulter',
        ADD: 'Ajouter'
      },
      CATEGORY: {
        COLLAPSIBLE: 'Catégories',
        CATEGORY:'Catégories',
        BADGE: '2',
        VIEW: 'Consulter',
        ADD: 'Ajouter'
      },
      RULE: {
        COLLAPSIBLE: 'Règles',
        RULE:'RULE',
        BADGE: '2',
        VIEW: 'Consulter',
        ADD: 'Ajouter'
      },
      USER: {
        COLLAPSIBLE: 'Users',
        USER:'USER',
        BADGE: '2',
        VIEW: 'Consulter',
        ADD: 'Ajouter'
      },
      ADMIN: {
        COLLAPSIBLE: 'Admins',
        ADMIN:'ADMIN',
        BADGE: '2',
        VIEW: 'Consulter',
        ADD: 'Ajouter'
      },
      COACH: {
        COLLAPSIBLE: 'Coach',
        COACH:'COACH',
        BADGE: '2',
        VIEW: 'Consulter',
        ADD: 'Ajouter'
      },
      ANECDOTE: {
        COLLAPSIBLE: 'Anecdotes',
        ANECDOTE:'Anecdote',
        BADGE: '2',
        VIEW: 'Consulter',
        ADD: 'Ajouter'
      },
      LEVELTEST: {
        COLLAPSIBLE: 'Tests de niveau',
        ANECDOTE:'Test de niveau',
        BADGE: '2',
        VIEW: 'Consulter',
        ADD: 'Ajouter'
      },
      DEFINITION: {
        COLLAPSIBLE: 'Définitions',
        ANECDOTE:'Définition',
        BADGE: '2',
        VIEW: 'Consulter',
        ADD: 'Ajouter'
      },
    },
  }
}
